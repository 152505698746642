$( document ).ready(function() {

	var md = 640,
			lg = 1024,
			xl = 1200,
			xxl = 1440;

	var headerHeight = $("header").outerHeight();
	var overallHeight = $(window).outerHeight();
	var overallWidth = $(window).outerWidth();

	$(window).resize(function() {
		overallHeight = $(window).outerHeight();
		overallWidth = $(window).outerWidth();
	});

	var scroll = $(window).scrollTop();
	$(window).scroll(function() {
		scroll = $(this).scrollTop();
	});

	// SET HOME PAGE INTRO AND PADDING BASED ON SCREEN HEIGHT
	function setIntroPadding() {
		$(".videoList-wrapper").css({
			paddingTop: overallHeight - 50
		});
	}
	setIntroPadding();

	$(window).resize(function() {
		setIntroPadding();
	});

	// FADE INTRO ON SCROLL
	var videoListPaddingTop = parseInt($(".videoList-wrapper").css("padding-top"));

	function introOpacity() {
		var fadePercent = 1 - (scroll / (videoListPaddingTop / 2));
		var fadePercentInverse = 1 - (scroll / (videoListPaddingTop / 2));
		$(".videoList-intro").css({
			"opacity": fadePercentInverse.toFixed(2)
		});
		$(".homeHero video").css({
			"opacity": fadePercentInverse.toFixed(2)
		});
		$(".homeHero iframe").css({
			"opacity": fadePercentInverse.toFixed(2)
		});
	}

	$(window).scroll(function() {
		introOpacity();
	});

	introOpacity();

	// SET EPISODE DESC HEIGHT
	function episodeDescHeight() {
		var playerHeight = $(".episode .player").outerHeight();
		console.log(playerHeight);
		if (overallWidth >= lg) {
			$(".episode-desc").outerHeight(playerHeight);
		} else {
			$(".episode-desc").removeAttr("style");
		}
	}
	episodeDescHeight();

	$(window).resize(function() {
		episodeDescHeight();
	});

	// EPISODE CELL HOVERS
	$(".videoList .cell").hover(function() {
		$(this).addClass("on");
	}, function() {
		$(this).removeClass("on");
	});

	// MENU TOGGLE
	$(".menuToggle").click(function() {
		$(this).next().toggleClass("on");
		$(this).toggleClass("on");
	});

});